<template>
	<div class="accordion" role="tablist">
		<template v-if="!isLoadingContract && selectedContract">
			<selectContractLabel />

			<template v-if="selectedContract.aquisicao_escritura == 'Não'">
				<b-card no-body class="mb-1">
					<b-card-header class="py-1 bg-primary justify-content-center" v-b-toggle.accordion-1>
						<h3 class="text-white text-center document-title m-0">
							Informe do Imposto de Renda
						</h3>
					</b-card-header>

					<b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
						<b-card class="m-0 py-1">
							<div :class="{ 'd-none': isLoading }">
								<div class="hideIR show">
									<b-row class="justify-content-center selectIR">
										<div class="col-md-6">
											<h5 class="text-center mb-2">Ano Base:</h5>
											<b-form-select
												v-if="selectedContract"
												v-model="IRSelectedDate"
												:options="options"
												class="text-center col-12 mt-1 mb-3"
											/>
										</div>
									</b-row>
									<b-button
										block
										variant="primary"
										class="col-md-3 mx-auto btnIR"
										@click="emitir(1, IRSelectedDate)"
										>Confirmar</b-button
									>
								</div>
							</div>
							<div :class="{ 'd-none': !isLoading }">
								<b-row class="d-flex justify-content-center">
									<h4>Aguarde...</h4>
								</b-row>
								<b-row class="d-flex justify-content-center">
									<b-spinner class="my-3" style="height: 3.5em width: 3.5em" />
								</b-row>
							</div>
						</b-card>
					</b-collapse>
				</b-card>

				<b-card no-body class="mb-1">
					<b-card-header class="py-1 bg-primary justify-content-center" v-b-toggle.accordion-2>
						<h3 class="text-white text-center document-title m-0">
							Extrato Financeiro
						</h3>
					</b-card-header>

					<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
						<b-card class="m-0 py-1">
							<div :class="{ 'd-none': isLoading }">
								<b-button
									block
									variant="primary"
									class="col-md-3 mx-auto show hideExtrato"
									@click="emitir(2)"
									>Confirmar</b-button
								>
							</div>

							<div :class="{ 'd-none': !isLoading }">
								<b-row class="d-flex justify-content-center">
									<h4>Aguarde...</h4>
								</b-row>
								<b-row class="d-flex justify-content-center">
									<b-spinner class="my-3" style="height: 3.5em width: 3.5em" />
								</b-row>
							</div>
						</b-card>
					</b-collapse>
				</b-card>

				<b-card no-body class="mb-1">
					<b-card-header class="py-1 bg-primary justify-content-center" v-b-toggle.accordion-3>
						<h3 class="text-white text-center document-title m-0">
							Anuência para Construção
						</h3>
					</b-card-header>

					<b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
						<b-card class="m-0 py-1">
							<div :class="{ 'd-none': isLoading }">
								<b-button
									block
									variant="primary"
									class="col-md-3 mx-auto show hideAnuencia"
									@click="emitir(3)"
									>Confirmar</b-button
								>
							</div>

							<div :class="{ 'd-none': !isLoading }">
								<b-row class="d-flex justify-content-center">
									<h4>Aguarde...</h4>
								</b-row>
								<b-row class="d-flex justify-content-center">
									<b-spinner class="my-3" style="height: 3.5em width: 3.5em" />
								</b-row>
							</div>
						</b-card>
					</b-collapse>
				</b-card>

				<b-card no-body class="mb-1" v-if="selectedContract.situacao == 50">
					<b-card-header class="py-1 bg-primary justify-content-center" v-b-toggle.accordion-4>
						<h3 class="text-white text-center document-title m-0">
							Autorização para Escritura
						</h3>
					</b-card-header>

					<b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
						<b-card class="m-0 py-1">
							<div :class="{ 'd-none': isLoading }">
								<b-button
									block
									variant="primary"
									class="col-md-3 mx-auto show hideEscritura"
									@click="emitir(4)"
									>Confirmar</b-button
								>
							</div>

							<div :class="{ 'd-none': !isLoading }">
								<b-row class="d-flex justify-content-center">
									<h4>Aguarde...</h4>
								</b-row>
								<b-row class="d-flex justify-content-center">
									<b-spinner class="my-3" style="height: 3.5em width: 3.5em" />
								</b-row>
							</div>
						</b-card>
					</b-collapse>
				</b-card>

				<b-card no-body class="mb-1" v-if="selectedContract.situacao == 50">
					<b-card-header class="py-1 bg-primary justify-content-center" v-b-toggle.accordion-5>
						<h3 class="text-white text-center document-title m-0">
							Termo de Quitação do Contrato
						</h3>
					</b-card-header>

					<b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
						<b-card class="m-0 py-1">
							<div :class="{ 'd-none': isLoading }">
								<b-button
									block
									variant="primary"
									class="col-md-3 mx-auto show hideQuitacao"
									@click="emitir(5)"
									>Confirmar</b-button
								>
							</div>

							<div :class="{ 'd-none': !isLoading }">
								<b-row class="d-flex justify-content-center">
									<h4>Aguarde...</h4>
								</b-row>
								<b-row class="d-flex justify-content-center">
									<b-spinner class="my-3" style="height: 3.5em width: 3.5em" />
								</b-row>
							</div>
						</b-card>
					</b-collapse>
				</b-card>
			</template>
			<template v-if="fluigDocuments">
				<b-card
					no-body
					class="mb-1"
					v-for="(fluigDocument, index) in fluigDocuments"
					:key="index"
				>
					<b-card-header
						class="py-1 bg-primary justify-content-center"
						v-b-toggle="'accordion-' + fluigDocument.id_documento"
					>
						<h3 class="text-white text-center document-title m-0">
							{{ fluigDocument.nome_documento }}
						</h3>
					</b-card-header>

					<b-collapse
						:id="'accordion-' + fluigDocument.id_documento"
						accordion="my-accordion"
						role="tabpanel"
					>
						<b-card class="m-0 py-1">
							<div :class="{ 'd-none': isLoading }">
								<b-button
									block
									variant="primary"
									class="col-md-3 mx-auto show"
									:class="'hide-' + fluigDocument.id_documento"
									@click="
										emitir(index + 6, fluigDocument.id_documento, fluigDocument.tipo_documento)
									"
								>
									Confirmar
								</b-button>
							</div>
							<div :class="{ 'd-none': !isLoading }">
								<b-row class="d-flex justify-content-center">
									<h4>Aguarde...</h4>
								</b-row>
								<b-row class="d-flex justify-content-center">
									<b-spinner class="my-3" style="height: 3.5em width: 3.5em" />
								</b-row>
							</div>
						</b-card>
					</b-collapse>
				</b-card>
			</template>
		</template>
		<template v-else>
			<b-card-body class="d-flex justify-content-center">
				<b-spinner class="mx-auto my-5" />
			</b-card-body>
		</template>
	</div>
</template>

<script>
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import selectContractLabel from '@/views/components/selectContractLabel.vue'
import store from '@/store'
import services from '@/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
Vue.use(BootstrapVue)

export default {
	data() {
		return {
			isLoading: false,
			isLoadingContract: false,
			fluigDocuments: [],
			IRSelectedDate: null,
		}
	},

	components: {
		selectContractLabel,
	},

	computed: {
		selectedContract() {
			return store.getters['contracts/getSelectedContract']
		},

		options() {
			if (this.selectedContract.data_venda) {
				let year = 0
				let anoVenda = Number(this.selectedContract.data_venda.split('-')[0])

				if (this.selectedContract.data_quitacao) {
					year = Number(this.selectedContract.data_quitacao.split('-')[0]) + 1
				} else {
					year = new Date().getFullYear()
				}

				let yearArray = Array.from(
					{ length: year - anoVenda },
					(value, index) => anoVenda + index
				).reverse()

				return yearArray
			}

			return []
		},
	},

	watch: {
		selectedContract: {
			async handler() {
				if (this.selectedContract) {
					this.isLoadingContract = true
					setTimeout(() => (this.isLoadingContract = false), 400)
					this.isLoading = false
					this.listFluigDocuments()
					this.IRSelectedDate = this.options[0]
				}
			},
		},
	},

	created() {
		this.listFluigDocuments()
		this.IRSelectedDate = this.options[0]
	},

	methods: {
		listFluigDocuments: async function() {
			this.fluigDocuments = await services.document.listContractDocuments(
				this.$apolloProvider.defaultClient
			)
		},

		emitir(document, idDocument, nameDocument) {
			this.isLoading = true
			this.emitirDocument(document, idDocument, nameDocument)
		},

		emitirDocument: async function(document, id, nameDocument) {
			const error = await services.document.emitirDocument(document, id, nameDocument)
			if (error) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Ops!',
						icon: 'XIcon',
						variant: 'danger',
						text: error,
					},
				})
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Sucesso!',
						icon: 'CheckIcon',
						variant: 'success',
						text: 'Documento emitido com sucesso!',
					},
				})
			}
			this.isLoading = false
		},
	},
}
</script>
<style>
.document-title {
	font-size: 1.2em;
	font-weight: bolder;
}
</style>
