export default {
  methods: {
    formatPriceTable: value => {
      if (value == "" || value == null || value == undefined) {
        return "R$ -"
      }
      let val = (value / 1).toFixed(2).replace(".", ",")
      return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    formatDate: dateValue => {
      if (dateValue == "" || dateValue == null || dateValue == undefined) {
        return "-/-/-"
      }
      if (typeof dateValue != "string") {
        dateValue = String(dateValue)
      }
      let year = dateValue.substr(0, 4)
      let month = dateValue.substr(5, 2)
      let day = dateValue.substr(8, 2)
      return day + "/" + month + "/" + year
    },

    formatNumber: value => {
      if (value == "" || value == null || value == undefined) {
        return "-"
      }
      let val = value.toString().replace(".", ",")
      return val
    },

    isEmptyObject: obj => {
      if (obj == "" || obj == null || obj == undefined) {
        return true
      }

      return Object.keys(obj).length === 0
    },
  },
}
