<template>
    <div>
        <SelectContract />
        <ListDocumentos/>
    </div>
</template>

<script>
import SelectContract from "@/views/components/selectContract.vue"
import ListDocumentos from "./ListDocumentos.vue"

export default {
    components  : {
        ListDocumentos,
        SelectContract
    },
}
</script>

<style>
</style>