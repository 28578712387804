<template>
  <b-card v-if="getSelectedContract">
    <b-card-text class="my-auto font-weight-bolder text-center text-md-left" id="label_text">
        <span>{{getSelectedContract.empreendimento_nome}}</span> <span class="d-none d-md-inline">-</span> <br class="d-block d-md-none">Quadra {{getSelectedContract.quadra}} - Lote {{getSelectedContract.lote}}
    </b-card-text>
  </b-card>
</template>

<script>
  import store from '@/store'
  import { BCard, BCardText } from 'bootstrap-vue'

 export default {
  components: {
    BCard,
    BCardText,
  },
  
  computed: {
    getSelectedContract() {
      return store.getters["contracts/getSelectedContract"]
    },
  }
 }
</script>